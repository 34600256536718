<template>
  <component :is="as" class="inline-flex items-center currency">
    <span dir="ltr">{{ currencyValue }} {{ value ? t(currency) : '' }}</span>
  </component>
</template>
<script setup lang="ts">
const props = defineProps({
  value: {
    type: null,
    required: true,
  },
  as: {
    type: String,
    default: 'span',
  },
  fallback: {
    type: String,
    default: undefined,
  },
  rounded: {
    type: Boolean,
    default: false,
  },
});
const currencyCode = inject(CONFIG_STORE_CURRENCY);
const innerValue = ref(props.value);
watch(
  () => props.value,
  newValue => {
    innerValue.value = newValue;
  }
);
const { t, n } = useI18n();

const currency = computed(() => currencyCode?.value || 'EG');
const currencyValue = computed(() => {
  if (innerValue.value === 0) {
    return props.fallback ? props.fallback : t('free');
  }
  if (innerValue.value) {
    return n(Number(innerValue?.value) || 0, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    });
  }

  if (props.fallback) return props.fallback;

  return t('toBeCalculated');
});
</script>

<i18n>
{
  "en": {
    "free": "Free"
  },
  "ar": {
    "free": "مجاناََ"
  }
}
</i18n>
